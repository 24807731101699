.paket-vip-general-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    background: #d8e1be;
    border-radius: 8px;

    p {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #141414;
    }
}

.label-musim-generalInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: 99px;
    height: 32px;
    background: #E0E0E0;
    border-radius: 8px;
}

.hastag-generalInfo {
    padding: 8px;
    gap: 8px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
}

.divider-generalInfo {
    width: 100%;
    height: 0px;
    border: 1px solid #E0E0E0;
}

.divider-vertical-generalInfo {
    width: 20px;
    height: 0px;
    border: 1px solid #E0E0E0;
    transform: rotate(90deg);
}

.divider-red-generalInfo {
    width: 80px;
    height: 0px;
    border: 2px solid #B80006;
}

.keterangan-generalInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: 24px;
}

.syarat-pendaftaran-generalInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04);
    border-radius: 16px;

    .title-generalInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 16px;
        background: #213B5B;
        border-radius: 16px 16px 0px 0px;

        h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
        }
    }

    .list-generalInfo {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 16px;
        gap: 8px;
        width: 100%;
        background: #FFFFFF;
    }
}

.syarat-pendaftaran-green-generalInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04);
    border-radius: 16px;

    .title-generalInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 16px;
        background: #73B17D;
        border-radius: 16px 16px 0px 0px;

        h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
        }
    }

    .list-generalInfo {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 16px;
        gap: 8px;
        width: 100%;
        background: #FFFFFF;
    }
}