.costum-toastify {
    @apply w-[400px]
}

.costum-toastify .Toastify__toast {
    @apply !rounded-2xl p-4 shadow-highlight
}

.costum-toastify .content {
    @apply flex gap-4
}

.costum-toastify .content .icon {
    @apply flex flex-col justify-center
}

.costum-toastify .content .section {
    @apply flex flex-col gap-2
}

.costum-toastify .content .section .title {
    @apply !font-bold text-black
}

.costum-toastify .content .section .message {
    @apply text-gray-3 text-sm
}