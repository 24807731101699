.rows:hover .action {
  position: absolute;
  background-color: #fbf3f2;
  opacity: 1;
}

.action {
  width: 208px;

  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  inset: 0;
  gap: 2rem;
  margin-right: 2rem;
}

.ant-spin-dot-item {
  background-color: red !important;
}
