.dokumen-paket-card {
    width: 286px;
    height: 360px;
    left: 1117px;
    top: 369px;
    box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
}

.title-paket-dokumen-card {
    width: 286px;
    height: 36px;
    background: #F2994A;
    border-radius: 16px 16px 0px 0px;
    padding-top: 8px;
    padding-left: 8px;


    h1 {
    width: 121px;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    }
}

.paket-dokumen-perlengkapan-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:hover {
        background: #FBF3F2;
    }
}