#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
#sidebar-1 {
  width: 100px !important;
  max-width: 100px !important;
  background: red;
}
.ant-menu-item-selected {
  background-color: #b039cc !important;
}

.btn-outline-adk{
  @apply border border-red-3 flex items-center justify-center w-full text-red-3 rounded-lg cursor-pointer py-2
}