.ant-picker {
    background: #f2f2f2;
    border-color: #f2f2f2;
    border-radius: 12px;
    font-family: "General Sans", sans-serif;
    padding: 14px 16px;
    &:hover {
        border-color: #595959;
    }
    &-focused {
        border-color: #595959 !important;
        box-shadow: none !important;
    }
    &-active-bar {
        display: none;
    }
    &-input {
        width: 80px !important;
        &:nth-child(2) {
            margin-right: 30px !important;
        }
       ::placeholder{
        color: rgba(0, 0, 0, 0.88) !important;
       }
    }
   
    &-range-separator {
        padding: 0 12px !important;
    }
    &-suffix {
        margin-left: auto !important;
        padding-left: 30px;
        width: fit-content !important;
    }
    &-clear {
        display: none;
    }
    &.single-date-picker {
        .ant-picker-input {
            width: 100% !important;
            min-width: 240px;
        }
    }
}

.popup-date-picker {
    font-family: "General Sans", sans-serif;
    thead {
        th {
            background: linear-gradient(
                204.44deg,
                #f90601 -18.49%,
                #8a1212 84.72%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-weight: 600 !important;
            text-fill-color: transparent;
        }
    }
    .ant-picker-panel-container {
        border-radius: 14px;
    }
    .ant-picker-header {
        border-bottom: 0;
        margin-top: 14px;
        &-super-prev-btn,
        &-super-next-btn {
            display: none;
        }
        > button {
            min-width: 2em;
        }
        * {
            line-height: 30px !important;
        }
    }
    .ant-picker-header-view {
        background: linear-gradient(
            34.99deg,
            #151515 17.46%,
            #3d3d3d 58.62%,
            #595959 87.32%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .ant-picker-cell-in-view {
        &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
                background: linear-gradient(
                    200.57deg,
                    #f9f218 3.37%,
                    #e2a246 86.36%
                ) !important;
                color: rgba(0, 0, 0, 0.88);
            }
        }
        &.ant-picker-cell-today {
            .ant-picker-cell-inner {
                background: linear-gradient(
                    200.57deg,
                    #f9f11834 3.37%,
                    #e2a14638 86.36%
                );
                border-radius: 4px;
                &::before {
                    border: none;
                }
            }
        }
        &.ant-picker-cell-in-range::before {
            background: linear-gradient(
                200.57deg,
                rgba(249, 242, 24, 0.1) 3.37%,
                rgba(226, 162, 70, 0.1) 86.36%
            );
        }
        &.ant-picker-cell-range-start {
            .ant-picker-cell-inner {
                background: linear-gradient(
                    200.57deg,
                    #f9f218 3.37%,
                    #e2a246 86.36%
                ) !important;
                color: rgba(0, 0, 0, 0.88);
            }
        }
        &.ant-picker-cell-range-start:not(
                .ant-picker-cell-range-start-single
            )::before {
            background: linear-gradient(
                200.57deg,
                rgba(249, 242, 24, 0.1) 3.37%,
                rgba(226, 162, 70, 0.1) 86.36%
            );
        }
        &.ant-picker-cell-range-start:not(
                .ant-picker-cell-range-start-single
            ):not(.ant-picker-cell-range-end)
            .ant-picker-cell-inner {
            background: linear-gradient(
                200.57deg,
                #f9f218 3.37%,
                #e2a246 86.36%
            );
            border-radius: 4px;
            color: rgba(0, 0, 0, 0.88);
        }
        &.ant-picker-cell-range-hover-end::before {
            background: linear-gradient(
                200.57deg,
                rgba(249, 242, 24, 0.2) 3.37%,
                rgba(226, 162, 70, 0.2) 86.36%
            );
        }
        &.ant-picker-cell-range-end .ant-picker-cell-inner {
            background: linear-gradient(
                200.57deg,
                #f9f218 3.37%,
                #e2a246 86.36%
            );
            border-radius: 4px;
            color: rgba(0, 0, 0, 0.88);
        }
        &.ant-picker-cell-range-end:not(
                .ant-picker-cell-range-start-end
            )::before {
            background: linear-gradient(
                200.57deg,
                rgba(249, 242, 24, 0.1) 3.37%,
                rgba(226, 162, 70, 0.1) 86.36%
            );
        }
        &.ant-picker-cell-range-hover {
            &::before {
                background: linear-gradient(
                    200.57deg,
                    rgba(249, 242, 24, 0.2) 3.37%,
                    rgba(226, 162, 70, 0.2) 86.36%
                ) !important;
                border: none;
            }
            &-start {
                &::before {
                    background: linear-gradient(
                        200.57deg,
                        rgba(249, 242, 24, 0.2) 3.37%,
                        rgba(226, 162, 70, 0.2) 86.36%
                    ) !important;
                }
                .ant-picker-cell-inner::after {
                    background: linear-gradient(
                        200.57deg,
                        rgba(249, 242, 24, 0.2) 3.37%,
                        rgba(226, 162, 70, 0.2) 86.36%
                    ) !important;
                }
            }
            &-end {
                &::before {
                    background: linear-gradient(
                        200.57deg,
                        rgba(249, 242, 24, 0.2) 3.37%,
                        rgba(226, 162, 70, 0.2) 86.36%
                    ) !important;
                }
                .ant-picker-cell-inner::after {
                    background: linear-gradient(
                        200.57deg,
                        rgba(249, 242, 24, 0.2) 3.37%,
                        rgba(226, 162, 70, 0.2) 86.36%
                    ) !important;
                }
            }
        }
        &.ant-picker-cell-range-end:not(
                .ant-picker-cell-range-end-single
            )::before {
        }
    }
}
